import { createStore } from 'vuex'
import axios from 'axios'
axios.create({
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'
  }
})

export default createStore({
  state: {
    darkMode: false,
    proposals: [],
    token: false,
    getProposals: {
      currentPage: 0,
      size: 10,
      page: 0,
      totalPage: ''
    }
  },
  getters: {
  },
  mutations: {
    toggleDarkMode(state) {
      state.darkMode = !state.darkMode
      if (state.darkMode) {
        document.documentElement.classList.add('dark')
      }else{
        document.documentElement.classList.remove('dark')
      }
      localStorage.setItem('darkMode', state.darkMode)
    },
    loadDarkMode(state) {
      state.darkMode = localStorage.getItem('darkMode') === 'true'
      if (state.darkMode) {
        document.documentElement.classList.add('dark')
      }
    },
    storeLogin(state, payload) {
      state.token = payload
      localStorage.setItem('token', state.token)
    },
    setParams(state, params) {
      state.getProposals.currentPage = params.currentPage
      state.getProposals.page = params.page
    },
    storeProposals(state, payload) {
      state.proposals = payload.content
      state.getProposals.totalPage = payload.totalPages
    },
    storeProposal(state, payload) {
      const index = state.proposals.findIndex(proposal => proposal.id === payload.id)
      if(index >= 0) {
        state.proposals.splice(index, 1, payload)
      }else{
      state.proposals.push(payload)
      }
    },
  },
  actions: {
    toggleDarkMode({ commit }) {
      commit('toggleDarkMode')
    },
    loadDarkMode({ commit }) {
      commit('loadDarkMode')
    },
    login({commit}, data) {
      return axios.post('https://api.psbcaninde.com.br/login', data).then((response) => {
        commit('storeLogin', response.data.token);
      }).catch((error) => {
        console.error('There was an error!', error);
      });
    },
    getProposalsAdmin({commit }, params) {
      commit('setParams', params)
      return axios.get('https://api.psbcaninde.com.br/proposal/admin', {
        headers: {
          Authorization: localStorage.getItem('token')
        },
        params: {
          size: params.size,
          page: params.page
        }
      }).then((response) => {
        commit('storeProposals', response.data)
      }).catch((error) => {
        console.error('There was an error!', error);
      });
    },
    getProposals({ commit }, params ) {
      commit('setParams', params)
      return axios.get("https://api.psbcaninde.com.br/proposal", {
        params: {
            size: params.size,
            page: params.page
        }
      }).then((response) => {
          commit('storeProposals', response.data);
      }).catch((error) => {
          console.error('There was an error!', error);
      });
    },
    addProposal({commit}, data) {
      return axios.post('https://api.psbcaninde.com.br/proposal', data).then((response) => {
        commit(response.data)
      }).catch((error) => {
        console.error('There was an error!', error);
      });
    },
  },
  modules: {
    darkMode: state => state.darkMode,
    token: state => state.token
  }
})
