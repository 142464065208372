<template>
  <header class="w-full">
    <!-- Seção 1 -->
    <div class="relative h-32 flex items-center justify-between p-4 bg-gradient-to-r from-yellow-500 to-orange-500">
      <img src="@/assets/imgs/psb-logo.png" alt="PSB Logo" class="h-24 object-contain ml-4">
      <div class="absolute right-0 p-4 flex justify-center items-center space-x-4">
        <a href="#" class="text-white text-2xl hidden md:block justify-center items-center">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="#" class="text-white text-2xl hidden md:block">
          <i class="fab fa-x-twitter"></i>
        </a>
        <a href="#" class="text-white text-2xl hidden md:block">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="#" class="text-white text-2xl hidden md:block">
          <i class="fab fa-tiktok"></i>
        </a>
        <router-link to="/login" class="text-white text-2xl hidden md:block">
          <i class="fa-solid fa-user"></i>
        </router-link>
        <button @click="toggleDarkMode" class="text-white text-2xl hidden md:block">
          <i :class="darkMode ? 'fas fa-sun' : 'fas fa-moon'"></i>
        </button>
        <button @click="toggleMobileMenu" :class="['text-white text-2xl md:hidden', { 'bg-orange-500 dark:text-white dark:bg-orange-500 h-8 w-8 rounded fixed top-4 right-4': isScrolled }]">
          <i class="fas fa-bars"></i>
        </button>
      </div>
    </div>

    <!-- Seção 2 -->
    <nav class="bg-red-600 dark:bg-gray-800 h-12 flex justify-center items-center shadow-md hidden md:block">
      <ul class="flex justify-center items-center space-x-8 h-full">
        <li>
          <router-link to="/" class="text-white dark:text-gray-300 hover:text-yellow-400 dark:hover:text-yellow-400">Início</router-link>
        </li>
        <li>
          <router-link to="/propostas" class="text-white dark:text-gray-300 hover:text-yellow-400 dark:hover:text-yellow-400">Sugerir Propostas</router-link>
        </li>
      </ul>
    </nav>

    <!-- Menu Lateral -->
    <div v-if="mobileMenuOpen" class="fixed inset-0 z-50 flex">
      <div class="w-9/12 bg-yellow-500 dark:bg-gray-800 p-4">
        <div class="flex justify-between items-center mb-4">
          <span class="text-gray-700 dark:text-gray-300 text-xl">Menu</span>
          <button @click="toggleMobileMenu" class="text-gray-700 dark:text-gray-300 text-2xl">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <ul class="space-y-4">
          <li>
            <router-link to="/" class="text-gray-700 dark:text-gray-300 hover:text-yellow-400 dark:hover:text-yellow-400" @click="toggleMobileMenu">Início</router-link>
          </li>
          <div class="flex justify-center items-center">
            <div class="w-9/12 border-b border-white dark:border-orange-500"></div>
          </div>
          <li>
            <router-link to="/propostas" class="text-gray-700 dark:text-gray-300 hover:text-yellow-400 dark:hover:text-yellow-400" @click="toggleMobileMenu">Sugerir Propostas</router-link>
          </li>
        </ul>
        <div class="mt-8 flex items-center justify-center space-x-4">
          <a href="#" class="text-orange-500 dark:text-white text-2xl flex justify-center items-center w-8 h-8 rounded-lg bg-white dark:bg-orange-500">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a href="#" class="text-orange-500 dark:text-white text-2xl flex justify-center items-center w-8 h-8 rounded-lg bg-white dark:bg-orange-500">
            <i class="fab fa-x-twitter"></i>
          </a>
          <a href="#" class="text-orange-500 dark:text-white text-2xl flex justify-center items-center w-8 h-8 rounded-lg bg-white dark:bg-orange-500">
            <i class="fab fa-instagram"></i>
          </a>
          <a href="#" class="text-orange-500 dark:text-white text-2xl flex justify-center items-center w-8 h-8 rounded-lg bg-white dark:bg-orange-500">
            <i class="fab fa-tiktok"></i>
          </a>
          <button @click="toggleDarkMode" class="text-orange-500 dark:text-white text-2xl flex justify-center items-center w-8 h-8 rounded-lg bg-white dark:bg-orange-500">
            <i :class="darkMode ? 'fas fa-sun' : 'fas fa-moon'"></i>
          </button>
        </div>
      </div>
      <div class="w-3/12 bg-gray-800 opacity-50" @click="toggleMobileMenu"></div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      mobileMenuOpen: false,
      isScrolled: false,
    };
  },
  computed: {
    darkMode() {
      return this.$store.getters.darkMode;
    }
  },
  methods: {
    toggleDarkMode() {
      this.$store.dispatch('toggleDarkMode');
    },
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
  },
  created() {
    this.$store.dispatch('loadDarkMode', this.darkMode);
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
/* Adicione quaisquer estilos adicionais aqui */
</style>
