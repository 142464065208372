<template>
    <footer class="w-full mt-auto bottom-0 bg-gradient-to-r from-yellow-500 to-orange-500 text-white py-6">
      <!-- Linha 1 -->
      <div class="flex flex-col md:flex-row justify-between items-center mb-4">
        <img src="@/assets/imgs/psb-logo.png" alt="PSB Logo" class="h-16 object-contain ml-4">
        <div class="flex top-0 right-0 p-4 flex space-x-4">
          <a href="#" class="text-white text-2xl">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a href="#" class="text-white text-2xl">
            <i class="fab fa-x-twitter"></i>
          </a>
          <a href="#" class="text-white text-2xl">
            <i class="fab fa-instagram"></i>
          </a>
          <a href="#" class="text-white text-2xl">
            <i class="fab fa-tiktok"></i>
          </a>
        </div>
      </div>
  
      <!-- Linha 2 -->
      <div class="text-center">
        <p>&copy; 2024 PSB. Todos os direitos reservados.</p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'MainFooter'
  };
  </script>
  
  <style scoped>
  /* Adicione quaisquer estilos adicionais aqui */
  </style>
  