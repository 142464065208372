import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'inicio',
    component: () => import('../views/main/StartView.vue')
  },
  {
    path: '/propostas',
    name: 'propostas',
    component: () => import('../views/main/ProposalView.vue')
  },
  {
    path: '/candidatos',
    name: 'candidatos',
    component: () => import('../views/main/StartCandidatesView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/dashboard/LoginView.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/dashboard/ProposalView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
